import { Injectable, NgZone } from '@angular/core';
import { MatDrawerMode } from '@angular/material/sidenav';

@Injectable()
export class DesktopService {

   private static readonly BREAK_POINT_XS = 600;
   private static readonly BREAK_POINT_SM = 960;
   private static readonly BREAK_POINT_MD = 1280;

   public readonly isEmbedded = (window.location.hash || '').toLowerCase().includes('embedded');
   public isMenuOpen = false;
   public menuMode: MatDrawerMode = 'over';
   public isDesktopLayout = false;
   public canShowSettingsPanel = false;

   constructor(
      private zone: NgZone
   ) {
      if (this.isEmbedded) {
         sessionStorage.setItem("asc-embedded", "true");
      }
      else {
         sessionStorage.removeItem("asc-embedded");
      }

      const observer = new ResizeObserver(entries => {
         this.zone.run(() => {
            const width = entries[0].contentRect.width;

            const isDesktopLayout = (width >= DesktopService.BREAK_POINT_MD);
            if (isDesktopLayout !== this.isDesktopLayout) {
               if (isDesktopLayout) {
                  this.setDesktopLayout();
               }
               else {
                  this.setMobileLayout();
               }
            }

            const showSettingsPanel = (width >= DesktopService.BREAK_POINT_SM);
            if (showSettingsPanel !== this.canShowSettingsPanel) {
               this.canShowSettingsPanel = showSettingsPanel;
            }
         });
      });

      observer.observe(window.document.body);
   }

   private setDesktopLayout() {
      this.isDesktopLayout = true;
      this.menuMode = 'side';
      this.isMenuOpen = true;
   }

   private setMobileLayout() {
      this.isDesktopLayout = false;
      this.isMenuOpen = false;
      this.menuMode = 'over';
   }

   public get isMobileLayout(): boolean {
      return !this.isDesktopLayout;
   }


   // ----------------------------------------------------------- Menu handling

   public get canShowMenuButton(): boolean {
      // Don't use navigation menu
      if (this.isEmbedded) return false;        // in embedded mode
      if (this.isDesktopLayout) return false;   // in desktop layout (menu is always visible)

      return true;
   }

   public openMenu() {
      if (this.isDesktopLayout) { return; }
      this.isMenuOpen = true;
   }

   public closeMenu() {
      if (this.isDesktopLayout) { return; }
      this.isMenuOpen = false;
   }

   public toggleMenu() {
      if (this.isDesktopLayout) { return; }
      this.isMenuOpen = !this.isMenuOpen;
   }
}
